import React from "react";
import { Link, NavLink } from "react-router-dom";

export default function Header() {
  return (
    <div>
      <div className="header fixed-top">
        <nav className="navbar navbar-expand-md">
          <div className="container">
            <Link className="navbar-brand" to="/">
              <div className="d-flex align-items-center">
                <img src="assets/images/logo.png" alt="logo" width="120px" />
              </div>
            </Link>
            <button
              className="navbar-toggler d-lg-none"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavId"
              aria-controls="collapsibleNavId"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavId">
              <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                <li className="nav-item mx-3">
                  <NavLink className="nav-link " to="/">
                    HOME
                  </NavLink>
                </li>
                <li className="nav-item mx-3">
                  <NavLink
                    className="nav-link "
                    to="about-us"
                    aria-current="page"
                  >
                    ABOUT US
                  </NavLink>
                </li>
                <li className="nav-item mx-3">
                  <NavLink className="nav-link" to="portfolios">
                    PORTFOLIOS
                  </NavLink>
                </li>
                <li className="nav-item mx-3">
                  <NavLink className="nav-link" to="services">
                    SERVICES
                  </NavLink>
                </li>
                <li className="nav-item mx-3">
                  <NavLink className="nav-link" to="contact-us">
                    CONNECT
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
