import logo from "./logo.svg";
import "./App.css";
import Header from "./layouts/Header";
import Sliders from "./layouts/Sliders";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Components/Pages/Home";
import Footer from "./layouts/Footer";
import { Route, Routes } from "react-router-dom";
import Services from "./Components/Pages/Services";
import Aboutus from "./Components/Pages/Aboutus";
import Portfolios from "./Components/Pages/Portfolios";
import { MessengerChat } from "react-messenger-chat-plugin";
import Contact from "./Components/Pages/Contact";

function App() {
  return (
    <div>
      <Header />
      <Routes>
        <Route index element={<Home />}></Route>
        <Route path="services" element={<Services />}></Route>
        <Route path="portfolios" element={<Portfolios />}></Route>
        <Route path="about-us" element={<Aboutus />}></Route>
        <Route path="contact-us" element={<Contact />}></Route>
      </Routes>

      <Footer />

      <MessengerChat
        pageId="108744051814579"
        language="en_US"
        themeColor={"#0d6efd"}
        bottomSpacing={15}
        loggedInGreeting="loggedInGreeting"
        loggedOutGreeting="loggedOutGreeting"
        greetingDialogDisplay={"show"}
        debugMode={true}
        onMessengerShow={() => {
          console.log("onMessengerShow");
        }}
      />
    </div>
  );
}

export default App;
