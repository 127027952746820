import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div>
      {/* <!-- footer --> */}
      <footer className="footer__area">
        <div className="footer__top">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="row">
                  <div className="col-lg-5">
                    <img
                      src="assets/images/logo_white.png"
                      alt="Footer Logo"
                      className="footer__logo"
                    />
                    <p className="p-10 mt-2 mb-3">
                      Corwebbd is a technical agency based in Dhaka, Bangladesh.
                      We had started website design & development in mind but
                      with time we are now a full-service software development
                      company but still known as the best web design company in
                      Bangladesh.{" "}
                    </p>

                    <ul className="social-media">
                      <li>
                        <a href="https://www.facebook.com/corewebbangladesh">
                          <i className="fa-brands fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-brands fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-brands fa-pinterest-p"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-4">
                    <h4>Quick Links</h4>
                    <div className="quick_links d-flex flex-column">
                      <Link to={"/"}>Home</Link>
                      <Link to={"about-us"}>About Us</Link>
                      <Link to={"portfolios"}>Portfolios</Link>
                      <Link to={"services"}>Services</Link>
                      <Link to={"contact-us"}>Contact-us</Link>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <h4>Contact with us</h4>
                    <div className="d-flex address_footer">
                      <i class="fa fa-location-arrow" aria-hidden="true"></i>
                      <span>Rakhalia, Raipur, Lakshmipur 3711, Bangladesh</span>
                    </div>
                    <div className="d-flex address_footer">
                      <i class="fa fa-phone" aria-hidden="true"></i>
                      <span>+88-018322-53784</span>
                    </div>
                    <div className="d-flex address_footer">
                      <i class="fa fa-envelope" aria-hidden="true"></i>
                      <span>azmathullah95@gmail.com</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* <!-- footer end --> */}
    </div>
  );
}
