import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Sliders() {
  var settings = {
    autoplay: true,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="hero_section py-5 mt-5">
        <div className="container">
          <div className="row">
            <Slider {...settings}>
              <div>
                <div className="row slider">
                  <div className="col-lg-6 align-self-center">
                    <section className="section_heading">
                      <h2>Wanna create website? for your Business</h2>
                    </section>
                    <section className="section_text py-4">
                      <p>
                        Corewebbd will help you to buildup your business from
                        sktech. Crafting dynamic and responsive digital
                        experiences harnessing advanced technology.
                      </p>
                      <div className="button-group slider_buttons">
                        <button
                          type="button"
                          className="btn btn-lg btn-primary"
                        >
                          Get our service
                        </button>
                        <button
                          type="button"
                          className="btn btn-lg btn-outline-primary"
                        >
                          Get our service
                        </button>
                      </div>
                    </section>
                  </div>
                  <div className="col-lg-6 d-flex flex-row-reverse">
                    <img
                      src="assets/images/webdev.png"
                      className="img-fluid"
                      alt="web_development"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="row slider">
                  <div className="col-lg-6 align-self-center">
                    <section className="section_heading">
                      <h2>Software Development</h2>
                    </section>
                    <section className="section_text py-4">
                      <p>
                        Corewebbd is the largest retail Software & solution
                        provider company in Bangladesh. We offer a comprehensive
                        and integrated business software suite with Hospital
                        Management System, School Management System, POS, VAT,
                        Accounting, HR, ERP, E-commerce etc. which is fully
                        developed on the latest Technology, for on-premise or on
                        cloud installation.
                      </p>
                      <div className="button-group slider_buttons">
                        <button
                          type="button"
                          className="btn btn-lg btn-primary"
                        >
                          Get our service
                        </button>
                        <button
                          type="button"
                          className="btn btn-lg btn-outline-primary"
                        >
                          Get our service
                        </button>
                      </div>
                    </section>
                  </div>
                  <div className="col-lg-6 d-flex flex-row-reverse">
                    <img
                      src="assets/images/software_development.png"
                      className="img-fluid"
                      alt="web_development"
                      width="80%"
                      height="50%"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="row slider">
                  <div className="col-lg-6 align-self-center">
                    <section className="section_heading">
                      <h2>Mobile Apps Development</h2>
                    </section>
                    <section className="section_text py-4">
                      <p>
                        We offer a wide range of technologies to deliver
                        exquisitely robust mobile app solutions that deliver
                        value to clients and give them a strong online presence.
                      </p>
                      <div className="button-group slider_buttons">
                        <button
                          type="button"
                          className="btn btn-lg btn-primary"
                        >
                          Get our service
                        </button>
                        <button
                          type="button"
                          className="btn btn-lg btn-outline-primary"
                        >
                          Get our service
                        </button>
                      </div>
                    </section>
                  </div>
                  <div className="col-lg-6 d-flex flex-row-reverse">
                    <img
                      src="assets/images/webdev.png"
                      className="img-fluid"
                      alt="web_development"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="row slider">
                  <div className="col-lg-6 align-self-center">
                    <section className="section_heading">
                      <h2>Social Media Marketing for your business</h2>
                    </section>
                    <section className="section_text py-4">
                      <p>
                        We will help you create a wholesome marketing strategy,
                        and ensure you add the entrepreneurial spirit to a
                        developing startup.
                      </p>
                      <div className="button-group slider_buttons">
                        <button
                          type="button"
                          className="btn btn-lg btn-primary"
                        >
                          Get our service
                        </button>
                        <button
                          type="button"
                          className="btn btn-lg btn-outline-primary"
                        >
                          Get our service
                        </button>
                      </div>
                    </section>
                  </div>
                  <div className="col-lg-6 d-flex flex-row-reverse">
                    <img
                      src="assets/images/marketing.png"
                      className="img-fluid"
                      alt="web_development"
                      width="80%"
                      height="50%"
                    />
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}
