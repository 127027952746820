import React from "react";
import { Helmet } from "react-helmet";

export default function Contact() {
  return (
    <div>
      <Helmet>
        <title>Contact with us | Corewebbd</title>
      </Helmet>

      <div class="container">
        <section class="contact-info">
          <h2>Contact with us</h2>
          <p>Contact with us for more details about the services you need</p>
          <br />

          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-12">
                <div class="contact-media">
                  {" "}
                  <i class="fa fa-paper-plane" aria-hidden="true"></i>
                  <h4 class="text-theme">Address</h4>
                  <div class="mb-3">
                    <h6 class="font-w-5">Lakshmipur Office:</h6>
                    <span class="text-black">
                      Rakhalia, Raipur, Lakshmipur 3711, Bangladesh
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mt-5 mt-lg-0">
                <div class="contact-media">
                  {" "}
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                  <h4 class="text-theme">Email Us</h4>
                  <p class="mb-3">
                    Email us for general queries, including wesbite and
                    softwares.
                  </p>{" "}
                  <a href="mailto:azmathullah95@gmail.com">
                    {" "}
                    azmathullah95@gmail.com
                  </a>
                  <a href="mailto:armahidetoaniK@gmail.com">
                    {" "}
                    armahidetoaniK@gmail.com
                  </a>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mt-5 mt-lg-0">
                <div class="contact-media">
                  {" "}
                  <i class="fa fa-phone" aria-hidden="true"></i>
                  <h4 class="text-theme">Call Us</h4>
                  <p class="mb-3">
                    Call us to speak to a member of our team.
                  </p>{" "}
                  <a href="tel:+8801832253784">+88-018322-53784</a>
                  <br />
                  <a href="tel:+8801961472921">+88-019614-72921</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
