import React from "react";

export const Teams = () => {
  const teams = [
    {
      name: "Azmath Ullah Rakib",
      designation: "CEO & Founder, Corewebbd",
      image: "assets/images/teams/azmath_ullah_rakib.jpg",
      fbLik: "https://www.facebook.com/azmathullah.rakib.5",
    },
    {
      name: "Mahide Hasan Anik",
      designation: "Software Developer",
      image: "assets/images/teams/mahide_hasan_anik.jpg",
      fbLik: "https://www.facebook.com/mahide41",
    },
    {
      name: "Estiak Hossain",
      designation: "Project Manager (Italy)",
      image: "assets/images/teams/estiak_hossain.jpg",
      fbLik: "#",
    },
    {
      name: "Ismail Hossain",
      designation: "Marketing Manager (Japan) ",
      image: "assets/images/teams/ismail_hossain.jpg",
      fbLik: "#",
    },
    {
      name: "Rozina khatun",
      designation: "Digital Marketing Manager",
      image: "assets/images/teams/rozina_khatun.jpg",
      fbLik: "https://www.facebook.com/profile.php?id=100085063471340",
    },
    {
      name: "Monika Mehazabin",
      designation: "Web & Android Developer",
      image: "assets/images/teams/monika.png",
      fbLik: "https://www.facebook.com/monika.caru",
    },
    {
      name: "Shirin Ahmed Laboni",
      designation: "UX/UI",
      image: "assets/images/teams/shirin.jpg",
      fbLik: "https://www.facebook.com/profile.php?id=100085063471340",
    },
    // {
    //   name: "Tangila Akter",
    //   designation: "Laravel Developer",
    //   image: "assets/images/teams/tangila_akter.jpg",
    //   fbLik: "https://www.facebook.com/tangila.akter.1694",
    // },
    {
      name: "Fateha Akther Saima",
      designation: "Frontend Desginer",
      image: "assets/images/teams/fateha_saima.jpg",
      fbLik: "https://www.facebook.com/fatehaakther.saima",
    },
    {
      name: "Saldanah Meherin",
      designation: "Content Writer & Digital Marketer",
      image: "assets/images/teams/meherin.png",
      fbLik: "https://www.facebook.com/profile.php?id=100085063471340",
    },
    {
      name: "Abida Sultana",
      designation: "Digital Marketing Officer",
      image: "assets/images/teams/rozina.png",
      fbLik: "https://www.facebook.com/profile.php?id=100085063471340",
    },

    {
      name: "Naima Akter",
      designation: "Web Designer",
      image: "assets/images/teams/naima.jpg",
      fbLik: "https://www.facebook.com/profile.php?id=100085063471340",
    },
    {
      name: "Marjahan Akter",
      designation: "Web Designer",
      image: "assets/images/teams/marjahan.jpg",
      fbLik: "https://www.facebook.com/profile.php?id=100085063471340",
    },

  ];
  return (
    <>
      {teams?.map((team, index) => (
        <>
          <div
            className="col-xl-3 col-lg-3 col-md-6 mb-60 wow tpfadeUp"
            data-wow-duration=".7s"
            data-wow-delay=".5s"
          >
            <div className="corewebbd-team team-inner text-center">
              <div className="corewebbd-team-thumb p-relative">
                <img src={team?.image} alt="" />
                <div className="corewebbd-team-thumb-icon">
                  <a href="#">
                    <i className="far fa-long-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div className="corewebbd-team-content">
                <h4 className="corewebbd-team-title">
                  <a href="#">{team?.name}</a>
                </h4>
                <span>{team?.designation}</span>
              </div>
              <div className="corewebbd-team-social">
                <a href={team?.fbLik}>
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fab fa-youtube"></i>
                </a>
                <a href="#">
                  <i className="fab fa-pinterest"></i>
                </a>
              </div>
            </div>
          </div>
        </>
      ))}
    </>
  );
};
