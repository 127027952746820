import $ from "jquery"; // Import jQuery
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

export default function Portfolios() {
  useEffect(() => {
    // JavaScript logic for filtering and adding/removing active_class
    // Move this logic directly into the useEffect
    $(document).ready(function () {
      $(".list").click(function () {
        const value = $(this).attr("data-filter");

        if (value === "all") {
          $(".listitem").show("1000");
        } else {
          $(".listitem")
            .not("." + value)
            .hide("2000");
          $(".listitem")
            .filter("." + value)
            .show("2000");
        }

        $(this).addClass("active_class").siblings().removeClass("active_class");
      });
    });
  }, []); // Empty dependency array to ensure this effect runs only once
  return (
    <div>
      <Helmet>
        <title>Portfolios | Corewebbd</title>
      </Helmet>
      <section className="container clients_websites">
        <h2 className="text-center">
          Our<span className="blue"> Clients</span>
        </h2>
        <div className="col-lg-12">
          <div className="datafilter">
            <ul>
              <li className="list active_className" data-filter="all">
                All
              </li>
              <li className="list" data-filter="webdesign">
                Web Development
              </li>
              <li className="list" data-filter="ecommerce">
                Ecommerce
              </li>
              <li className="list" data-filter="newspaper">
                News Paper
              </li>
              <li className="list" data-filter="portfolio">
                Portfolio
              </li>
            </ul>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 listitem newspaper webdesign">
              <div className="grid">
                <figure className="effect-zoe">
                  <a target="_blank" href="https://www.probashbook.com">
                    {" "}
                    <img
                      src="assets/images/portfolios/probashbook.png"
                      alt="Probashbook"
                    />
                  </a>
                  <figcaption>
                    <h2>Probashbook</h2>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 listitem newspaper webdesign">
              <div className="grid">
                <figure className="effect-zoe">
                  <a target="_blank" href="https://www.bdexgroup.com">
                    {" "}
                    <img
                      src="assets/images/portfolios/bdexgroup.png"
                      alt="BDEX GROUP"
                    />
                  </a>
                  <figcaption>
                    <h2>BDEX GROUP</h2>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 listitem newspaper">
              <div className="grid">
                <figure className="effect-zoe">
                  <a target="_blank" href="https://shimantobarta.com/">
                    {" "}
                    <img
                      src="assets/images/portfolios/shimantobarta_corewebbd.jpg"
                      alt="Daily News 24 corewebbd"
                    />
                  </a>
                  <figcaption>
                    <h2>Shimantobarta</h2>
                  </figcaption>
                </figure>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 listitem ecommerce">
              <div className="grid">
                <figure className="effect-zoe">
                  <a target="_blank" href="https://priohut.com/">
                    <img
                      src="assets/images/portfolios/priohut.png"
                      alt="fnf_computer | corewebbd"
                    />
                  </a>
                  <figcaption>
                    <h2>eCommerce with Apps - Prihut</h2>
                  </figcaption>
                </figure>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 listitem ecommerce">
              <div className="grid">
                <figure className="effect-zoe">
                  <a target="_blank" href="https://fnfcomputer.com/">
                    <img
                      src="assets/images/portfolios/fnf_computer.png"
                      alt="fnf_computer | corewebbd"
                    />
                  </a>
                  <figcaption>
                    <h2>FNF Computer</h2>
                  </figcaption>
                </figure>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 listitem ecommerce">
              <div className="grid">
                <figure className="effect-zoe">
                  <a target="_blank" href="https://sleepify.com.bd/">
                    <img
                      src="assets/images/portfolios/sleepify.com.bd-2021.png"
                      alt="sleepify | corewebbd"
                    />
                  </a>
                  <figcaption>
                    <h2>sleepify</h2>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 listitem portfolio">
              <div className="grid">
                <figure className="effect-zoe">
                  <a target="_blank" href="http://dam-health.org/">
                    <img
                      src="assets/images/portfolios/dam-health.png"
                      alt="Health Sector of Dhaka Ahsania Mission | corewebbd"
                    />
                  </a>
                  <figcaption>
                    <h2>
                      Health Sector<span> of Dhaka Ahsania Mission</span>
                    </h2>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 listitem ecommerce">
              <div className="grid">
                <figure className="effect-zoe">
                  <a target="_blank" href="https://gemshousebd.com/">
                    <img
                      className="img-fluid"
                      src="assets/images/portfolios/gemshousebd.png"
                      alt="gemshousebd corewebbd"
                    />
                  </a>
                  <figcaption>
                    <h2>
                      Gems <span> House BD</span>
                    </h2>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 listitem portfolio">
              <div className="grid">
                <figure className="effect-zoe">
                  <a target="_blank" href="https://timesfoodbd.com/">
                    <img
                      className="img-fluid"
                      src="assets/images/portfolios/timesfoodbd.jpg "
                      alt="timesfoodbd corewebbd"
                    />
                  </a>
                  <figcaption>
                    <h2>
                      Times <span> Food</span>
                    </h2>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 listitem ecommerce">
              <div className="grid">
                <figure className="effect-zoe">
                  <a target="_blank" href="https://niyatbazar.com">
                    <img
                      className="img-fluid"
                      src="assets/images/portfolios/niyatbazar.jpg "
                      alt="niyatbazar corewebbd"
                    />
                  </a>
                  <figcaption>
                    <h2>
                      Niyat <span> Bazar</span>
                    </h2>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 listitem ecommerce webdesign">
              <div className="grid">
                <figure className="effect-zoe">
                  <a target="_blank" href="https://warsisurgical.com.bd/">
                    <img
                      className="img-fluid"
                      src="assets/images/portfolios/warsis.jpg "
                      alt="waris corewebbd"
                    />
                  </a>
                  <figcaption>
                    <h2>
                      Warsi <span>Surgical</span>
                    </h2>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 listitem ecommerce">
              <div className="grid">
                <figure className="effect-zoe">
                  <a target="_blank" href="https://4sktrade.com/">
                    <img
                      className="img-fluid"
                      src="assets/images/portfolios/4sktrade.jpg "
                      alt="4sktrade corewebbd"
                    />
                  </a>
                  <figcaption>
                    <h2>
                      4SK <span>Trade</span>
                    </h2>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 listitem portfolio">
              <div className="grid">
                <figure className="effect-zoe">
                  <a target="_blank" href="https://goopybagha.com/">
                    <img
                      className="img-fluid"
                      src="assets/images/portfolios/goopy_bagha.jpg "
                      alt="googpy_bagha corewebbd"
                    />
                  </a>
                  <figcaption>
                    <h2>
                      Goopy <span>Bagha</span>
                    </h2>
                  </figcaption>
                </figure>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 listitem ecommerce">
              <div className="grid">
                <figure className="effect-zoe">
                  <a target="_blank" href="https://bhuiyanfurniture.com/">
                    <img
                      src="assets/images/portfolios/bf.jpg "
                      alt="bhuiyan_furniture corewebbd"
                    />
                  </a>
                  <figcaption>
                    <h2>
                      Bhuiyan <span>Furniture</span>
                    </h2>
                  </figcaption>
                </figure>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 listitem ecommerce">
              <div className="grid">
                <figure className="effect-zoe">
                  <a target="_blank" href="https://fitandshineltd.com/">
                    {" "}
                    <img
                      src="assets/images/portfolios/fs.jpg "
                      alt="fitandshine corewebbd"
                    />
                  </a>
                  <figcaption>
                    <h2>
                      Fit and <span>Shine</span>
                    </h2>
                  </figcaption>
                </figure>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 listitem newspaper">
              <div className="grid">
                <figure className="effect-zoe">
                  <a target="_blank" href="https://dailynews24by7.com/">
                    {" "}
                    <img
                      src="assets/images/portfolios/dn24.jpg"
                      alt="Daily News 24 corewebbd"
                    />
                  </a>
                  <figcaption>
                    <h2>
                      Daily News<span>24/7</span>
                    </h2>
                  </figcaption>
                </figure>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 listitem webdesign">
              <div className="grid">
                <figure className="effect-zoe">
                  <a target="_blank" href="http://amicpepsep.org/">
                    <img
                      src="assets/images/portfolios/amicpepsep.jpg "
                      alt="Dhaka Ahsania Mission corewebbd"
                    />
                  </a>
                  <figcaption>
                    <h2>
                      Dhaka Ahsania<span>Mission</span>
                    </h2>
                  </figcaption>
                </figure>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 listitem webdesign">
              <div className="grid">
                <figure className="effect-zoe">
                  <a target="_blank" href="https://www.dctoh.com/">
                    <img
                      src="assets/images/portfolios/dctoh.jpg"
                      alt="Dhaka conference on tobacco or health 2020 corewebbd"
                    />
                  </a>
                  <figcaption>
                    <h2>
                      Dhaka Conference on <span>Tobacco or Health 2020</span>
                    </h2>
                  </figcaption>
                </figure>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 listitem portfolio">
              <div className="grid">
                <figure className="effect-zoe">
                  <a target="_blank" href="https://www.iqbalmasud.com/">
                    {" "}
                    <img
                      src="assets/images/portfolios/iqbalmasud.jpg "
                      alt="Iqbal Masud corewebbd"
                    />
                  </a>
                  <figcaption>
                    <h2>
                      Iqbal<span> Masud</span>
                    </h2>
                  </figcaption>
                </figure>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 listitem portfolio">
              <div className="grid">
                <figure className="effect-zoe">
                  <a target="_blank" href="https://kbacexsa.org/">
                    <img
                      src="assets/images/portfolios/kba.jpg "
                      alt="GOvt. KBA Schol Student Association corewebbd"
                    />
                  </a>
                  <figcaption>
                    <h2>
                      Govt. KBA EX<span> Student Association</span>
                    </h2>
                  </figcaption>
                </figure>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 listitem portfolio">
              <div className="grid">
                <figure className="effect-zoe">
                  <a target="_blank" href="https://www.ndsl-bd.com/">
                    <img
                      src="assets/images/portfolios/ndsl.jpg "
                      alt="Nurani Dyeing  &amp; Sweater Ltd. corewebbd"
                    />
                  </a>
                  <figcaption>
                    <h2>
                      Nurani Dyeing<span> &amp; Sweater Ltd.</span>
                    </h2>
                  </figcaption>
                </figure>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 listitem  portfolio">
              <div className="grid">
                <figure className="effect-zoe">
                  <a target="_blank" href="http://naltacam.org/">
                    <img
                      src="assets/images/portfolios/naltacam.jpg "
                      alt="Nalta Central Ahsania Mission corewebbd"
                    />
                  </a>
                  <figcaption>
                    <h2>
                      Nalta Central<span> Ahsania Mission</span>
                    </h2>
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
