import AOS from "aos";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Teams } from "../teams/Teams";

export default function Aboutus() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <Helmet>
        <title>About Us | Corewebbd</title>
      </Helmet>
      {/* <!-- about area --> */}
      <section className="about__area">
        <div data-aos="fade-up" data-aos-duration="800" className="container">
          <div data-aos="fade-up" data-aos-duration="800" className="row">
            <div
              data-aos="fade-up"
              data-aos-duration="800"
              className="col-xxl-6 col-xl-6 col-lg-6 col-md-6"
            >
              <div
                data-aos="fade-up"
                data-aos-duration="800"
                className="about__left"
              >
                <img src="assets/images/1.png" alt="Image" className="image" />
                <img
                  src="assets/images/2.png"
                  alt="Image"
                  className="image-2"
                />
                <img
                  src="assets/images/3.jpeg"
                  alt="Image"
                  className="image-3"
                />
                <img src="assets/images/s1.png" alt="shape" className="shape" />
              </div>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="800"
              className="col-xxl-6 col-xl-6 col-lg-6 col-md-6"
            >
              <div
                data-aos="fade-up"
                data-aos-duration="800"
                className="about__right"
              >
                <div
                  data-aos="fade-up"
                  data-aos-duration="800"
                  className="about__right-inner"
                >
                  <h2
                    className="sec-sub-title wow animate__ animate__fadeInUp animated"
                    data-wow-delay="0.2s"
                  >
                    About Company
                  </h2>
                  <h3
                    className="sec-title wow animate__ animate__fadeInUp animated"
                    data-wow-delay="0.4s"
                  >
                    6+ YEARS WORKING
                    <span> EXPERIENCE.</span>
                  </h3>
                  <p
                    className="wow animate__ animate__fadeInUp animated"
                    data-wow-delay="0.4s"
                  >
                    Corwebbd, headquartered in Bangladesh, started its journey
                    as a prominent web design company and has rapidly
                    transformed into a comprehensive software development
                    powerhouse. Despite this evolution, we proudly retain our
                    reputation as the best web design company in Bangladesh,
                    setting the industry standard for creative excellence and
                    user-focused design.
                  </p>
                  <p>
                    Our expansion beyond web design is driven by a commitment to
                    innovation and quality. We offer a wide array of software
                    services, including custom software development, mobile app
                    solutions, UI/UX design, and digital marketing. Our team of
                    skilled professionals collaborates to create tailored,
                    future-proof solutions that align with our clients'
                    objectives. Our success stems from a client-centric
                    approach, transparent communication, and a dedication to
                    delivering results that have earned us the trust of
                    businesses across various industries. As we continue to
                    expand our horizons, we remain committed to upholding our
                    reputation as a technology leader in Bangladesh while
                    providing top-notch web design services that distinguish us
                    in the market.
                  </p>
                  <ul
                    className="list-check wow animate__ animate__fadeInUp animated"
                    data-wow-delay="0.4s"
                  >
                    <li>Web development solutions</li>
                    <li>Software development</li>
                    <li>App development</li>
                    <li>Web Hosting Services</li>
                  </ul>
                  <a
                    href="#"
                    className="cxu-btn-primary wow animate__ animate__fadeInUp animated"
                    data-wow-delay="0.4s"
                  >
                    READ MORE
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- about area end --> */}
      {/* <!-- team start --> */}
      <div
        data-aos="fade-up"
        data-aos-duration="800"
        className="corewebbd-taem-area pb-80"
      >
        <div data-aos="fade-up" data-aos-duration="800" className="container">
          <div
            className="row wow tpfadeUp"
            data-wow-duration=".9s"
            data-wow-delay=".3s"
          >
            <div
              data-aos="fade-up"
              data-aos-duration="800"
              className="corewebbd-team-section-area text-center mb-60"
            >
              <h3 className="corewebbd-section-subtitle">Our Team</h3>
              <h4 className="corewebbd-section-title-sm">Meet with our team</h4>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="800" className="row">
            <Teams />
          </div>
        </div>
      </div>
      {/* <!-- team end --> */}
    </div>
  );
}
